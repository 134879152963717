import {DecimalPipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {HemnetService, IHemnetData} from '../../services/hemnet.service'

@Component({
  selector: 'spb-object-info',
  templateUrl: './object-info.component.html',
  styleUrls: ['./object-info.component.scss'],
  imports: [DecimalPipe]
})
export class ObjectInfoComponent implements OnInit, OnDestroy {

  public imageUrl = 'assets/no-thumb.png'

  public name = 'Ingen adress 1a'

  public askingPrice = 0

  public area = 0

  public year = 2000

  public fee = 0

  private hemnetOutput$: Subscription = new Subscription()

  constructor(
    private hemnetService: HemnetService
  ) {
  }

  ngOnInit(): void {
    this.hemnetOutput$ = this.hemnetService.hemnetOutput.subscribe({
      next: (hemnetData: IHemnetData) => {
        this.imageUrl = hemnetData.image || 'assets/no-thumb.svg'
        this.year = hemnetData.year
        this.area = hemnetData.area
        this.fee = hemnetData.fee
        this.askingPrice = hemnetData.askingPrice
        this.name = hemnetData.name
      }
    })
  }

  ngOnDestroy(): void {
    this.hemnetOutput$.unsubscribe()
  }
}
