import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {ApplicationConfig} from '@angular/core'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'

import {routes} from './app.routes'
import {WINDOW} from './application/app'
import {authInterceptor} from './application/auth.interceptor'


registerLocaleData(localeFr, 'fr', localeFrExtra)
export const appConfig: ApplicationConfig = {
  providers: [
    {provide: WINDOW, useValue: window},
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        authInterceptor
      ])
    )
  ]
}