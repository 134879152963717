import {Routes} from '@angular/router'
import {HemnetComponent} from './hemnet/hemnet.component'

export const routes: Routes = [
  {
    path: 'hemnet',
    component: HemnetComponent
  },
  {
    path: '**',
    redirectTo: 'hemnet'
  }
]