import {Component} from '@angular/core'
import { RouterOutlet } from '@angular/router'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  imports: [RouterOutlet]
})
export class AppComponent {
}
