{
  "name": "hemnet-app",
  "version": "1.4.1-81933922.0",
  "scripts": {
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "watch": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.1",
    "@angular/cdk": "^19.2.2",
    "@angular/common": "^19.2.1",
    "@angular/compiler": "^19.2.1",
    "@angular/core": "^19.2.1",
    "@angular/forms": "^19.2.1",
    "@angular/material": "^19.2.2",
    "@angular/platform-browser": "^19.2.1",
    "@angular/platform-browser-dynamic": "^19.2.1",
    "@angular/router": "^19.2.1",
    "rxjs": "~7.8.0",
    "tslib": "^2.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.1",
    "@angular/cli": "^19.2.1",
    "@angular/compiler-cli": "^19.2.1",
    "@angular/language-service": "^19.2.1",
    "@sparbanken-syd/personnummer": "^3.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.3",
    "@types/jasmine": "~5.1.7",
    "@types/node": "^22.13.10",
    "@typescript-eslint/eslint-plugin": "^8.26.1",
    "@typescript-eslint/parser": "^8.26.1",
    "angular-eslint": "^19.2.1",
    "eslint": "^9.22.0",
    "jasmine-core": "~5.6.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "postcss": "^8.5.3",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.15.0",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.11.1",
    "typescript": "~5.8.2",
    "typescript-eslint": "^8.26.1"
  }
}
