import {Component} from '@angular/core'
import {FooterComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import version from '../../../assets/package.json'

@Component({
  selector: 'spb-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  imports: [
    FooterComponent
  ]
})
export class ContactComponent {
  public version = version.version
  public copyrightYears = `2020-${new Date().getFullYear()}`
}

