<spb-footer [license]="true" [version]="version"
            [copyrightYears]="copyrightYears"
></spb-footer>
<div class="contact-holder">
  <div class="contact">
    <div class="contact-button">
      <a href="https://www.sparbankensyd.se/kontakt">Kontakta oss</a>
    </div>
  </div>
</div>
